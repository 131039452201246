<template>
  <div class="col-md-4">
    <div class="class-card">
      <div class="_image-wrap">
        <img :src="recording.program_image" alt="" />

        <div class="_date-box">
          {{ recording.start_time }} - {{ recording.end_time }}
        </div>
        <router-link
          :to="{ name: 'recording', params: { id: recording.id } }"
          class="btn cta-single"
          v-if="recording.status == 'SUCCESS'"
        >
          <i class="fas fa-play"></i>
        </router-link>
      </div>
      <div class="_body-wrap">
        <div class="d-flex">
          <h6>{{ recording.subject_title }}</h6>
          <p class="proccessing" v-if="recording.status != 'SUCCESS'">
            Recording Proccessing..
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecordingCard",
  components: {},
  props: ["recording"]
};
</script>

<style lang="scss">
@import "@/style/class-card.scss";
p.proccessing {
  margin-left: 30px;
}
</style>
